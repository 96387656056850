import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAll() {
    return this.http.get<any[]>(`${environment.api}/projects`).toPromise();
  }


  getById(id: string) {
    return this.http.get<any>(`${environment.api}/projects/${id}`).toPromise();
  }

  create(project: any) {
    return this.http.post(`${environment.api}/projects`, project);
  }

  update(id: string, project: any) {
    return this.http.put(`${environment.api}/projects/${id}`, project);
  }

  delete(id: string) {
    return this.http.delete(`${environment.api}/projects/${id}`);
  }
}
